import { Inject, Injectable, InjectionToken, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
  S3File,
  FileData,
  ProjectFromFileAndScenarios,
} from '@core/models/s3-file.model';
import { Scenario } from '@core/models/scenario.model';
import { RUNTIME_CONFIGURATION } from '@core/tokens/runtime-configuration.token';

import { map } from 'rxjs';

export const STORAGE_ENDPOINT = new InjectionToken('STORAGE_ENDPOINT', {
  providedIn: 'root',
  factory: () => `${inject(RUNTIME_CONFIGURATION).apiUrl}/storage`,
});

export type ParsedFileData = Record<string, any>;

@Injectable({ providedIn: 'root' })
export class StorageService {
  constructor(
    private readonly http: HttpClient,
    @Inject(STORAGE_ENDPOINT) private readonly endpoint: string
  ) {}

  public projectsAndSenarios() {
    return this.http
      .get<S3File[]>(this.endpoint)
      .pipe(
        this.mapToProjectStructure(),
        this.removeReferenceData(),
        this.mapToProjectAndSenarios()
      );
  }

  private mapToProjectStructure() {
    return map<S3File[], ParsedFileData>((files) => {
      const projects: ParsedFileData = {};
      for (const file of files) {
        const filePath = file.filePath.split('/');
        let current = projects;
        for (let i = 0; i < filePath.length; i++) {
          const key = filePath[i];
          if (i === filePath.length - 1) {
            current[key] = file;
          } else {
            current = current[key] ?? (current[key] = {});
          }
        }
      }
      return projects;
    });
  }

  private removeReferenceData() {
    return map<ParsedFileData, ParsedFileData>((data) => {
      delete data.reference_data;
      return data;
    });
  }

  private extractScenarios(data: FileData) {
    if (!data) {
      return [];
    }

    /* remove csv files so only vis folders remain */
    const runFolders = Object.entries(data)
      .filter(([key]) => !key.includes('.csv'))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {} as FileData);

    /**
     * create object where key is the scenario name
     * and value is array of csv output files for the
     * scenario
     */
    const scenarios: Scenario[] = [];
    Object.keys(runFolders).forEach((runId) => {
      Object.keys(data[runId].visualisation ?? []).forEach((scenarioId) => {
        const id = runId + '-' + scenarioId;
        const visFolder = data[runId].visualisation;
        if (visFolder) {
          scenarios.push({
            [id]: Object.keys(visFolder[scenarioId]),
          });
        }
      });
    });
    return scenarios;
  }

  private mapToProjectAndSenarios() {
    return map<ParsedFileData, Array<ProjectFromFileAndScenarios>>((data) =>
      Object.keys(data).map((k) => ({
        scenarios: this.extractScenarios(data[k].outputs.deployment_model),
        details: { ProjectNumber: k, ShortTitle: k },
      }))
    );
  }
}
