import { Inject, Injectable, InjectionToken, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import {
  Project,
  AnyProject,
  ProjectAndSenarios,
} from '@core/models/project.model';
import { StorageService } from '@core/services/storage.service';
import { ProjectFromFileAndScenarios } from '@core/models/s3-file.model';
import { RUNTIME_CONFIGURATION } from '@core/tokens/runtime-configuration.token';

import { map, combineLatestWith } from 'rxjs';

export const PROJECT_ENDPOINT = new InjectionToken<string>('PROJECT_ENDPOINT', {
  providedIn: 'root',
  factory: () => `${inject(RUNTIME_CONFIGURATION).apiUrl}/projects`,
});

@Injectable({ providedIn: 'root' })
export class ProjectsService {
  constructor(
    private readonly http: HttpClient,
    private readonly storageService: StorageService,
    @Inject(PROJECT_ENDPOINT) private readonly endpoint: string
  ) {}

  public all() {
    return this.http
      .get<Array<Project>>(this.endpoint)
      .pipe(
        combineLatestWith(this.storageService.projectsAndSenarios()),
        this.mapToProjectAndSenarios()
      );
  }

  public search(searchTerm: string) {
    const endpoint = `${this.endpoint}/search`;
    const params = new HttpParams({ fromObject: { search_term: searchTerm } });
    return this.http.get<Array<AnyProject>>(endpoint, { params });
  }

  public update(project: Project) {
    return this.http.put<Project>(this.endpoint, project);
  }

  private mapToProjectAndSenarios() {
    /* combine projects and senarios parsed from
     * storage files with projects returns from
     * the API using the project nuber as the
     * referance key. remove any projects and
     * senarios that do not have a matching
     * project from the API.
     */
    return map<
      [Array<Project>, Array<ProjectFromFileAndScenarios>],
      Array<ProjectAndSenarios>
    >(
      ([, projectsAndSenarios]) =>
        projectsAndSenarios.map((projectAndSenarios) => ({
          ...projectAndSenarios,
          details: projectAndSenarios.details,
        })) as Array<ProjectAndSenarios>
    );
  }
}
